import {Component, EventEmitter, Input, Output} from "@angular/core";
import { Router, RouterLinkActive, RouterLink } from "@angular/router";
import {RootScope} from "../shared/RootScope.service";
import {ApiService} from "../shared/Api.service";
import { AuthenticationService, FileDownloadService, SwxModule } from "swx.front-end-lib";
import {MenuItem, MenuItemGroup} from "./MenuItem";
import {DomSanitizer} from "@angular/platform-browser";
import { HasPermissionPipe } from "../shared/HasPermission.pipe";
import { NgIf, NgFor, NgStyle, NgClass } from "@angular/common";

@Component({
    selector: 'swx-menu',
    templateUrl: './Menu.component.html',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgStyle,
        RouterLinkActive,
        NgClass,
        RouterLink,
        SwxModule,
        HasPermissionPipe,
    ],
})
export class MenuComponent {
    @Input() itemGroups: MenuItemGroup[];
    @Output() itemSelected = new EventEmitter<{item: MenuItem, isTopLevel: boolean}>();
    @Output() subMenuClosed = new EventEmitter<void>();
    @Output() logout = new EventEmitter<void>();
    
    constructor(private router: Router,
                public $root: RootScope,
                private api: ApiService,
                private authentication: AuthenticationService,
                private fileDownloadService: FileDownloadService,
                private sanitizer: DomSanitizer) {
    }
    
    open(event, item: MenuItem, isTopLevel: boolean) {
        event.stopPropagation();
        this.itemSelected.emit({item: item, isTopLevel: isTopLevel})
    }
    
    openSettings(event) {
        this.open(event, { title: 'Settings', route: ['/userProfile'], color: '#2B5257'}, true);
    }
    
    onLogout() {
        this.logout.emit();
    }

    downloadHeaderDocument() {
        this.api.ClientPortalDocument
            .export().then(response => {
            this.fileDownloadService.download(response.body, header => response.headers.get(header));
        });
    }

    closeSubmenu(event) {
        event.stopPropagation();
        this.subMenuClosed.emit();
    }

    // Trust the image url, needed for SVG
    getImageFor(logo: string) {
        return this.sanitizer.bypassSecurityTrustUrl(logo)
    }
}
