import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HasPermissionService } from '../shared/HasPermission.pipe';
import { SwxModule } from 'swx.front-end-lib';

@Component({
    templateUrl: 'Index.component.html',
    standalone: true,
    imports: [SwxModule]
})
export class IndexComponent implements OnInit {
    constructor(
        private router: Router,
        private hasPermissionService: HasPermissionService,
    ) {
    }

    ngOnInit(): void {
        if (this.hasPermissionService.hasPermission('HOTs')) {
            this.router.navigateByUrl('/hots');
        } else if (this.hasPermissionService.hasPermission('Dashboard')) {
            this.router.navigateByUrl('/dashboard');
        } else if (this.hasPermissionService.hasPermission('Dispatch')) {
            this.router.navigateByUrl('/dispatch');
        }
    }
}