<swx-dialog>
    <swx-dialog-header>{{title}}</swx-dialog-header>
    <swx-dialog-content class="weatherTypePopup">
        <div class="list">
            <label class="item item-radio" *ngFor="let weatherType of weatherTypes|orderBy:['Order','Name']" (click)="weatherType.Items?.length > 0 ? open(weatherType) : selectWeatherType(weatherType);">
                <div class="radio-content" [style.border]="(weatherType == selectedWeatherMenuItem) ? '5px solid #387ef5' : '1px solid gray'" [style.margin-bottom]="'5px'">
                    <div class="item-content item-button-right">
                        {{(weatherType.Name)}}
                        <span *ngIf="weatherType.Items?.length > 0" class="mdi mdi-chevron-right mdi-24px"></span>
                    </div>
                </div>
            </label>
        </div>
    </swx-dialog-content>
</swx-dialog>
