import { Component, Input } from '@angular/core';
import { DispatchAirport } from '../shared/Api.service';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component({
    selector: 'dispatch-airport-weather-icon',
    templateUrl: 'DispatchAirportWeatherIcon.component.html',
    standalone: true,
    imports: [NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault]
})
export class DispatchAirportWeatherIconComponent {
    @Input('airport') airport: DispatchAirport;
}