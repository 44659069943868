import { Component, OnChanges, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { RootScope } from '../shared/RootScope.service';
import { ThroughputGraphSample } from '../shared/Api.service';
import { IResourceItemList, GoogleChartsService } from 'swx.front-end-lib';
import jQuery from 'jquery';

@Component({
    selector: 'throughput-graph',
    templateUrl: 'ThroughputGraph.component.html',
    standalone: true
})
export class ThroughputGraphComponent implements OnChanges, AfterViewInit {
    @Input('samples') samples: IResourceItemList<ThroughputGraphSample>;
    @Input('count') count: number;
    @Input('options') options: google.visualization.ColumnChartOptions;
    @ViewChild('chartEl', { static: false }) chartEl: ElementRef<HTMLElement>;
    columnChart: google.visualization.ColumnChart;
    chartLoad: any;

    constructor(
        private googleChartsLoaderService: GoogleChartsService,
        public $root: RootScope,
    ) {
        var resizeTimer;
        jQuery(window).bind('resize', () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                this.drawChart();
            }, 250);
        });
    }

    ngAfterViewInit(): void {
        this.googleChartsLoaderService.loadGoogleCharts().then(() => {
            this.columnChart = new google.visualization.ColumnChart(this.chartEl.nativeElement);
        });
    }

    ngOnChanges(): void {
        if (this.samples == null) return;

        this.googleChartsLoaderService.loadGoogleCharts().then(() => {
            this.drawChart();
        });
    }

    private drawChart(): void {
        this.googleChartsLoaderService.loadGoogleCharts().then(() => {
            Promise.all([this.samples.$promise]).then(() => {
                let dataTable = new google.visualization.DataTable();
                dataTable.addColumn('datetime', this.$root.currentUser.UseLocalTime ? 'Local time' : 'UTC');
                dataTable.addColumn('number', 'Avg Push Back to Taxi In');
                dataTable.addColumn('number', 'Avg Taxi In to ParkIn');
                dataTable.addColumn('number', 'Avg Deicing Time');
                dataTable.addColumn('number', 'Avg CTM (Call Time) to Taxi Out');
                dataTable.addColumn('number', 'Avg Taxi Out to Wheels Off');

                this.samples.forEach(sample => {
                    var row: any[] = [
                        this.parseDate(sample.To),
                        sample.PushBackToTaxiInAverage,
                        sample.TaxiInToIceManAcceptAverage,
                        sample.DeicingTimeAverage,
                        sample.IcehouseAcceptOutToTaxiOutAverage,
                        sample.TaxiOutToWheelsUpAverage,
                    ];

                    dataTable.addRow(row);
                });

                // Apply formatters
                const formatter = new google.visualization.NumberFormat({ fractionDigits: 1 });
                [1, 2, 3, 4, 5].forEach(columnIndex => formatter.format(dataTable, columnIndex));

                this.options = {
                    ...this.options,
                    height: 500,
                    //width: '100%',
                    backgroundColor: {
                        stroke: '#31b0d5',
                        strokeWidth: 1
                    },
                    isStacked: true,
                    //allowHtml: true,
                    //fill: 20,
                    hAxis: {
                        title: 'Time',
                        format: 'HH:mm',
                        gridlines: {
                            count: this.count
                        },
                        viewWindowMode: 'maximized',
                    },
                    legend: {
                        position: 'bottom',
                        maxLines: 3
                    },
                    //enableInteractivity: false,
                    //interpolateNulls: true,
                    vAxis: {
                        title: 'Average Throughput Time (minutes)',
                        viewWindow: {
                            min: 0,
                            //max: maxHot > 48 ? 240 : (maxHot > 24 ? 48 : 24)
                        }
                    }
                };

                this.columnChart.draw(dataTable, this.options);
            });
        });
    }

    private parseDate(dateString) {
        var parts = dateString.split(/[^0-9]/);
        return new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
    }
}
