import {Component, Input, OnInit} from "@angular/core";
import { FedExShift } from "../shared/Api.service";
import { FedExShiftService } from "./FedExShift.service";
import { SwxModule } from "swx.front-end-lib";
import {NgFor, NgClass } from "@angular/common";
import { SearchBoxComponent } from "../searchBox/SearchBox.component";

type Shift = {
    name: string;
    shifts: Array<{
        discipline: string;
        shifts: FedExShift[];
    }>
}

@Component({
    templateUrl: './FedExShiftSelectionDialog.component.html',
    styleUrls: ['./FedExShiftSelectionDialog.component.css'],
    standalone: true,
    imports: [SearchBoxComponent, NgFor, NgClass, SwxModule]
})
export class FedExShiftSelectionDialogComponent implements OnInit
{
    @Input('container') container: JQuery;
    
    shiftsByShiftName = [];
    selectedIds = new Set<number>();
    selectedShift: Shift;
    searchTerm = '';

    constructor(private shiftService: FedExShiftService) {
        
    }
    
    ngOnInit() {
        this.shiftService.getAllShifts().then(shifts => {
            this.selectedIds = this.shiftService.getSelectedIds();
            
            // group shifts by name, then by discipline, sorted by discipline
            this.shiftsByShiftName = [...new Set(shifts.map(shift => shift.Name))]
                .map(shiftName => ({
                    name: shiftName,
                    shifts: shifts.filter(shift => shift.Name === shiftName)
                }))
                .filter(g => g.shifts.length > 0)
                .map(byName => ({
                    name: byName.name,
                    shifts: [...new Set(byName.shifts.map(s => s.Discipline))]
                        .map(discipline => ({
                            discipline: discipline,
                            shifts: byName.shifts.filter(s => s.Discipline === discipline)
                        }))
                        .filter(g => g.shifts.length > 0)
                }))
                .map(result => ({
                    ...result,
                    shifts: [...result.shifts].sort((a, b) => 
                        a.discipline.localeCompare(b.discipline))
                }))
            
            this.selectedShift = this.shiftsByShiftName[0];
        })
    }

    selectShift(shift: Shift) {
        this.selectedShift = shift;
    }
    
    filteredDesks(desks: any): FedExShift[] {
        if (desks) {
            return desks.filter(d => d.Desk.includes(this.searchTerm))
        }
        return []
    }
    
    close: () => any;

    toggleShift(evt: any, shift: FedExShift) {
        if (evt.target.tagName === 'INPUT') {
            if (evt.target.checked) {
                this.selectedIds.add(shift.Id)
            }
            else {
                this.selectedIds.delete(shift.Id);
            }
        }
    }

    isSelected(shift: FedExShift): boolean {
        return this.selectedIds.has(shift.Id);
    } 

    confirmSelection() {
        this.shiftService.selectShifts(this.selectedIds);
        this.container.dialog('close');
    }
    
    cancelSelection() {
        this.container.dialog('close');
    }
    
    filterDesks(searchTerm: string) {
        this.searchTerm = searchTerm.toUpperCase().trim();
    }
}
