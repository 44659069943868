import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { RootScope } from '../shared/RootScope.service';
import { CLIENT_CODE } from '../shared/ClientCode.const';
import { AuthenticationService, NotificationService, BusyIndicatorService, SwxModule } from 'swx.front-end-lib';
import { AuthGuard } from '../shared/AuthGuard.service';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'Login.component.html',
    standalone: true,
    imports: [
        FormsModule,
        NgIf,
        RouterLink,
        SwxModule,
    ],
})
export class LoginComponent {
    username: string;
    password: string;
    loginError = false;
    showLoginForm = false;
    loginAttempted = false;

    constructor(
        private router: Router,
        public $root: RootScope,
        private authentication: AuthenticationService,
        private authGuard: AuthGuard,
        private notification: NotificationService,
        private busyIndicator: BusyIndicatorService,
    ) {
        this.$root.currentClient.$promise.then(() => {
            if (this.$root.currentClient.PortalUseSaml2Authentication
                    && window.location.search.indexOf('skipsaml') === -1) {
                this.authGuard.samlLoginRedirect();
            } else {
                this.showLoginForm = true;
            }
        });
    }

    login() {
        this.busyIndicator.setBusy(true);
        this.loginAttempted = true;

        if (this.username == null || this.password == null) {
            return;
        }

        const username = `${CLIENT_CODE.toLowerCase()}$${this.username}`;
        
        this.authentication.setCredentials(username, this.password)
            .then(() => {
                this.busyIndicator.setBusy(false);
                this.router.navigateByUrl('/');
            }, error => {
                this.busyIndicator.setBusy(false);
                if (error.status === 400) {
                    this.loginError = true;
                } else {
                    this.notification.show(error.statusText, { type: 'error', sticky: true });
                }
            });
    }
}
