<div class="lweGrid contentWrapper">
    <form #form="ngForm" (submit)="refresh()" class="toolbar gridFilters">
        <label *ngIf="stations.length > 1">
            <span class="caption">{{'Station'|translate}}</span>
            <multiselect name="query.StationId" [(ngModel)]="query.StationId">
                <multiselect-option *ngFor="let option of stations|orderBy:'Name'" [value]="option.Id" [name]="option.Name"></multiselect-option>
            </multiselect>
        </label>
        <label *ngIf="sensorSelectMatrices.length > 1">
            <span class="caption">{{'Sensor select matrix'|translate}}</span>
            <multiselect name="query.SensorSelectMatrixId" [(ngModel)]="query.SensorSelectMatrixId">
                <multiselect-option *ngFor="let option of sensorSelectMatrices|orderBy:'Name'" [value]="option.Id" [name]="option.Name"></multiselect-option>
            </multiselect>
        </label>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="FromDate" />
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="ToDate" />
        </label>
        <filters name="query.Filters" [(ngModel)]="query.Filters" [columnDefs]="columnDefs"></filters>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        </label>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <button type="submit" [disabled]="!form.valid || !query.FromDate || !hasRequiredFilters()"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        </label>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <button type="button" [disabled]="!form.valid || !query.FromDate || !hasRequiredFilters()" (click)="grid.export()"><span class="mdi mdi-download"></span> {{'Export'|translate}}</button>
        </label>
    </form>

    <grid #grid
          gridId="preferences.Change.ColumnExport"
          [query]="query"
          [resource]="api.WeatherProfile"
          [exportResource]="api.WeatherProfileExport"
          [headerHeight]="75"
          [columnDefs]="columnDefs">
    </grid>
</div>
