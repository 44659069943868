import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { SwxModule } from 'swx.front-end-lib';

@Component({
    templateUrl: 'ResetPassword.component.html',
    standalone: true,
    imports: [SwxModule, NgIf, RouterLink, FormsModule]
})
export class ResetPasswordComponent implements OnInit {
    token: string;
    password: string;
    repeatPassword: string;
    sent = false;
    error = false;

    constructor(
        private route: ActivatedRoute,
        private api: ApiService
    ) {
    }

    ngOnInit(): void {
        this.token = this.route.snapshot.params['id'];

        this.api.PasswordResetToken.queryObject({
            token: this.token
        }, { ignoreErrors: true }).$promise.then(result => {
            this.error = !result.Valid;
        });
    }

    submit() {
        this.api.ResetPassword.post({
                Token: this.token,
                Password: this.password
            }, { ignoreErrors: true })
            .then(result => {
                    if (result.Success) {
                        this.sent = true;
                    } else {
                        this.error = true;
                    }
                },
                () => {
                    this.error = true;
                }
            );
    }
}
