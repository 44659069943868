<div class="contentWrapper container">
    <form class="form" #form="ngForm" (submit)="submit()">
        <div *ngIf="isUserAdded" class="alert alert-success">
            <span>{{'Thank you for registering. A confirmation e-mail has been sent to you. Please click on the link in the email in order to complete your registration.'}}</span>
        </div>
        <fieldset *ngIf="!isUserAdded">
            <h3 class="title">{{'Sure HOT + Register'}}</h3>
            <div>
                <div class="form-group">
                    <label>{{'Email:'}}</label>
                    <input type="email" required class="form-control" name="username" [(ngModel)]="username" placeholder="test@example.com" [pattern]="usernameRegex">
                    <span class="small">{{'Valid domains: ' + allowedDomains}}</span>
                </div>
                <div class="form-group">
                    <label>{{'Password:'}}</label>
                    <input type="password" required class="form-control" [(ngModel)]="password" name="password" minlength="6" />
                </div>
                <input type="submit" class="btn btn-info" [disabled]="!form.form.valid" value="{{'Register'|translate}}" />
            </div>
        </fieldset>
    </form>
</div>

