import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild} from "@angular/core";
import {debounceTime, distinctUntilChanged, fromEvent, Subscription} from "rxjs";

@Component({
    selector: 'search-box',
    templateUrl: './SearchBox.component.html',
    styleUrls: ['./SearchBox.component.css'],
    standalone: true
})
export class SearchBoxComponent implements AfterViewInit, OnDestroy {
    @Output() search = new EventEmitter<string>();
    @ViewChild('input') input: ElementRef;
    sub: Subscription;
    
    ngAfterViewInit() {
        this.sub = fromEvent(this.input.nativeElement, 'keyup')
            .pipe(debounceTime(500))
            .pipe(distinctUntilChanged())
            .subscribe(_ => {
                this.search.emit(this.input.nativeElement.value);
            })
    }
    
    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
