import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SwxModule } from 'swx.front-end-lib';
import { NgClass, NgStyle, NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'dispatch-airport-legend',
    templateUrl: './DispatchAirportLegend.component.html',
    styleUrls: ['./DispatchAirportLegend.component.scss'],
    standalone: true,
    imports: [NgClass, NgStyle, NgFor, NgIf, SwxModule]
})
export class DispatchAirportLegendComponent implements OnChanges {
    @Input() hotConditions: Array<{ Id: number, Name: string, Color: string, Rank: number, Icon: string, ShowAlertWhenReached: boolean }>;
    open: boolean = false;
    defaultIcon = 'mdi-map-marker';
    defaultColor = 'black';

    ngOnChanges(changes: SimpleChanges): void {
        // To be displayed on the legend a condition needs eithen an icon or a color
        if (changes['hotConditions'].currentValue && changes['hotConditions'].currentValue.length > 0) {
            let filtered = this.hotConditions.filter(condition => condition.Color || condition.Icon);
            this.hotConditions = [...filtered];
        }
    }

    toggle(): void {
        this.open = !this.open;
    }

    getToggleIcon(): string {
        return `mdi ${this.open ? 'mdi-chevron-down' : 'mdi-chevron-up'}`;
    }

    classForIcon(hotCondition): string {
        return `mdi ${hotCondition.Icon || this.defaultIcon}`;
    }

    colorForIcon(hotCondition): string {
        if (hotCondition.Color)
            return `#${hotCondition.Color}`;

        return this.defaultColor;
    }
}
