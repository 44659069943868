import { Component, ViewChild } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';
import { RootScope } from '../shared/RootScope.service';
import { ApiService } from '../shared/Api.service';
import jQuery from 'jquery';
import { GridComponent, SwxModule, GridModule } from "swx.front-end-lib";
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'EngineCoverAlertGrid.component.html',
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgIf,
        NgFor,
        GridModule,
    ],
})
export class EngineCoverAlertGridComponent {
    airports = this.api.ClientAirport.query();
    engineCoverAlertTypes = this.api.EngineCoverAlertType.query();
    datePickerOptions: any;
    setUpPromise: any;
    query: any;
    @ViewChild('grid', { static: true }) grid: GridComponent;
    columnDefs = [
	    { colId: "Id", field: "Id", headerName: "#", width: 90, pinned: "left", },
	    { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 100, valueFormatter: c => c.value ? moment.utc(c.value).format('YYYY-MM-DD') : "", pinned: "left", filterType: "date" },
	    { colId: "CreatedTime", field: "CreatedDate", headerName: "Created time", width: 70, valueFormatter: c => c.value ? moment.utc(c.value).format('HH:mm') : "", excelIgnore: true, pinned: "left", "searchable": false },
        { colId: "Type", field: "Type", headerName: "Type", width: 100, pinned: "left", cellClass: c => c.value ?? "", valueFormatter: c => c.value? this.engineCoverAlertTypes[c.value] : "", filterType: 'enum', source: 'EngineCoverAlertType' },
        { colId: "AirportICAOCode", field: "AirportICAOCode", headerName: "Airport", width: 80, pinned: "left" },
	    { colId: "LastUpdateDate", field: "LastUpdate", headerName: "Last update date", width: 100, valueFormatter: c => c.value ? moment.utc(c.value).format('YYYY-MM-DD') : "", filterType: "date" },
	    { colId: "LastUpdateTime", field: "LastUpdate", headerName: "Last update time", width: 70, valueFormatter: c => c.value ? moment.utc(c.value).format('HH:mm') : "", excelIgnore: true, "searchable": false },
	    { colId: "Body", field: "Body", headerName: "Email", cellRenderer: c => {
		    var cellContent = document.createElement('div');

		    if (!c.value) return cellContent;
            
		    cellContent.innerHTML = '<a href class="mdi mdi-eye"></a>';
            cellContent.querySelector('a').addEventListener('click', e => {
	            e.preventDefault();

                var bodyIndex = c.value?.lastIndexOf("<body>");
                const body = bodyIndex > -1 ? (c.value?.substring(bodyIndex + 6, c.value.lastIndexOf("</body>"))) : c.value;

	            var recipients = "<table class=\"table\">"
		            + "<tr><th>Recipient</th><th>Status</th><th>Status updated on</th></tr>"
		            + c.data.Emails.map(e => "<tr><td>" + e.Name + " &lt;" + e.Email + "&gt;</td><td style=\"" + (e.AmazonSESStatus === 'Bounce' ? 'background-color: #ffaaaa;' : '') + "\">" + (e.AmazonSESStatus || '') + "</td><td>" + (e.AmazonSESStatusUpdateDate ? moment.utc(e.AmazonSESStatusUpdateDate).format('YYYY-MM-DD HH:mm') : "") + "</td></tr>").join('')
		            + "</table>";

	            var dialogContainer = jQuery('<div></div>');
	            dialogContainer.html(recipients + body);
                dialogContainer.dialog({
	                title: c.data.Subject,
	                width: 650,
	                height: 600,
	                modal: true,
	                open: () => {
		                jQuery('.ui-widget-overlay')
			                .bind('click',
				                () => {
					                dialogContainer.dialog('close');
				                });
	                }
                });
            });
		    return cellContent;
	    }, width: 50, pinned: "left" },
	    { colId: "Emails", field: "Emails", headerName: "Recipients", "flex": 1, "minWidth": 300, valueFormatter: c => c.data ? c.data.Emails.map(e => e.Name + " <" + e.Email + ">").join(', ') : "" },
    ];

    constructor(
	    private $root: RootScope,
        public api: ApiService,
    ) {
        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        };

        this.setUpPromise = Promise.all([this.airports.$promise]);

        if (window.localStorage['engineCoverAlertQuery']) {
            this.query = JSON.parse(window.localStorage['engineCoverAlertQuery']);
        } else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            AirportId: [],
            FromDate: null,
            ToDate: null,
            Filters: []
        };
    };
    
    hasRequiredFilters() {
        return Object.keys(this.query).filter((item) => {
            return (Array.isArray(this.query[item]) && this.query[item].length > 0) || this.query[item] !== null;
        }).length > 0;
    }

    refresh() {
        window.localStorage['engineCoverAlertQuery'] = JSON.stringify(this.query);
        this.grid.refresh();
    }
}
