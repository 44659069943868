import { Component, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { RootScope } from '../shared/RootScope.service';
import { ApiService, ClientHotCondition } from '../shared/Api.service';
import { IResourceItem, IResourceItemList, TranslateService, NotificationService, ConfirmService, SwxModule } from 'swx.front-end-lib';
import { HasPermissionPipe } from '../shared/HasPermission.pipe';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

@Component({
    templateUrl: 'DispatchAirportProfileList.component.html',
    standalone: true,
    imports: [SwxModule, NgIf, FormsModule, RouterLink, NgFor, HasPermissionPipe]
})
export class DispatchAirportProfileListComponent implements OnInit {
    moment = moment;
    items: IResourceItemList<any>;
    searchQuery;
    orderByField;
    includeInactive;
    tab;
    @ViewChild('ngForm', { static: true }) ngForm;
    mappings: IResourceItemList<any>;
    hotConditions: IResourceItemList<ClientHotCondition>;
    icons: Array<any>;

    constructor(
        private $root: RootScope,
        private api: ApiService,
        private translateService: TranslateService,
        private notification: NotificationService,
        private confirmService: ConfirmService,
    ) {
    }

    ngOnInit() {
        this.refresh();
        
        this.tab = location.hash ? location.hash.substring(1) : 'configurations';
    }

    refresh() {
        this.items = this.api.DispatchAirportProfile.query({ IncludeInactive: true });
        this.orderByField = 'item.Name';

        this.mappings = this.api.PortalUserDispatchAirportConfiguration.query();
        this.hotConditions = this.api.ClientHotCondition.query();

        this.mappings.$promise.then(() => {
            var maxRank = Math.max.apply(null, this.mappings
                .map(h => h.Rank));

            this.mappings.forEach(mapping => {
                if (mapping.Rank == null) {
                    mapping.Rank = ++maxRank;
                }
            });
        });

        this.icons = [
            { char: null, cssClass: null },
            { char: '\u{f01a}', cssClass: 'mdi-adjust' },
            { char: '\u{f01e}', cssClass: 'mdi-airplane-off' },
            { char: '\u{f01d}', cssClass: 'mdi-airplane' },
            { char: '\u{f5d5}', cssClass: 'mdi-airplane-takeoff' },
            { char: '\u{f020}', cssClass: 'mdi-alarm' },
            { char: '\u{f06b}', cssClass: 'mdi-av-timer' },
            { char: '\u{f5ce}', cssClass: 'mdi-clock-alert' },
            { char: '\u{f51a}', cssClass: 'mdi-timelapse' },
            { char: '\u{f51b}', cssClass: 'mdi-timer' },
            { char: '\u{f51e}', cssClass: 'mdi-timer-off' },
            { char: '\u{f51f}', cssClass: 'mdi-timer-sand' },
            { char: '\u{f6ac}', cssClass: 'mdi-timer-sand-empty' },
            { char: '\u{f026}', cssClass: 'mdi-alert' },
            { char: '\u{f027}', cssClass: 'mdi-alert-box' },
            { char: '\u{f028}', cssClass: 'mdi-alert-circle' },
            { char: '\u{f5d6}', cssClass: 'mdi-alert-circle-outline' },
            { char: '\u{f029}', cssClass: 'mdi-alert-octagon' },
            { char: '\u{f02a}', cssClass: 'mdi-alert-outline' },
            { char: '\u{f2d6}', cssClass: 'mdi-help' },
            { char: '\u{f2d7}', cssClass: 'mdi-help-circle' },
            { char: '\u{f625}', cssClass: 'mdi-help-circle-outline' },
            { char: '\u{f2fc}', cssClass: 'mdi-information' },
            { char: '\u{f2fd}', cssClass: 'mdi-information-outline' },
            { char: '\u{f64e}', cssClass: 'mdi-information-variant' },
            { char: '\u{f12e}', cssClass: 'mdi-checkbox-blank' },
            { char: '\u{f12f}', cssClass: 'mdi-checkbox-blank-circle' },
            { char: '\u{f130}', cssClass: 'mdi-checkbox-blank-circle-outline' },
            { char: '\u{f131}', cssClass: 'mdi-checkbox-blank-outline' },
            { char: '\u{f132}', cssClass: 'mdi-checkbox-marked' },
            { char: '\u{f133}', cssClass: 'mdi-checkbox-marked-circle' },
            { char: '\u{f156}', cssClass: 'mdi-close' },
            { char: '\u{f157}', cssClass: 'mdi-close-box' },
            { char: '\u{f158}', cssClass: 'mdi-close-box-outline' },
            { char: '\u{f159}', cssClass: 'mdi-close-circle' },
            { char: '\u{f15a}', cssClass: 'mdi-close-circle-outline' },
            { char: '\u{f09a}', cssClass: 'mdi-bell' },
            { char: '\u{f09b}', cssClass: 'mdi-bell-off' },
            { char: '\u{f09c}', cssClass: 'mdi-bell-outline' },
            { char: '\u{f09d}', cssClass: 'mdi-bell-plus' },
            { char: '\u{f09e}', cssClass: 'mdi-bell-ring' },
            { char: '\u{f09f}', cssClass: 'mdi-bell-ring-outline' },
            { char: '\u{f0a0}', cssClass: 'mdi-bell-sleep' },
            { char: '\u{f208}', cssClass: 'mdi-eye' },
            { char: '\u{f23e}', cssClass: 'mdi-flag-outline-variant' },
            { char: '\u{f240}', cssClass: 'mdi-flag-variant' },
            { char: '\u{f094}', cssClass: 'mdi-flask-empty' },
            { char: '\u{f095}', cssClass: 'mdi-flask-empty-outline' },
            { char: '\u{f361}', cssClass: 'mdi-message' },
            { char: '\u{f365}', cssClass: 'mdi-message-outline' },
            { char: '\u{f523}', cssClass: 'mdi-tooltip' },
            { char: '\u{f526}', cssClass: 'mdi-tooltip-outline' },
            { char: '\u{f3e5}', cssClass: 'mdi-pause-circle' },
            { char: '\u{f3e6}', cssClass: 'mdi-pause-circle-outline' },
            { char: '\u{f3e7}', cssClass: 'mdi-pause-octagon' },
            { char: '\u{f3e8}', cssClass: 'mdi-pause-octagon-outline' },
        ];
    }

    delete(item: IResourceItem) {
        this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            item.$delete().then(() => {
                this.notification.show(this.translateService.translate('The item has been deleted.'));
                this.refresh();
            });
        }).catch(() => {});
    }

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    saveUsers() {
        this.api.PortalUserDispatchAirportConfiguration.post(this.mappings.map(m => ({
            PortalUserId: m.Id,
            DispatchAirportProfileId: m.DispatchAirportProfileId
        }))).then(() => {
            this.notification.show(this.translateService.translate('The changes have been saved.'));
        });
    }

    saveAlerts() {
        this.api.ClientHotCondition.post(this.hotConditions.map(m => ({
            Id: m.Id,
            Rank: m.Rank,
            Color: m.Color,
            Icon: m.Icon,
            ShowAlertWhenReached: m.ShowAlertWhenReached,
        }))).then(() => {
            this.notification.show(this.translateService.translate('The changes have been saved.'));
        });
    }
}
