import {Component, Inject, OnInit} from "@angular/core";
import {FedExShift} from "../shared/Api.service";
import {FedExShiftService} from "./FedExShift.service";
import {DialogComponent, DialogContentComponent, DialogFooterComponent, DialogHeaderComponent, IResourceItemList, SwxModule} from "swx.front-end-lib";
import {NgClass} from "@angular/common";
import {SearchBoxComponent} from "../searchBox/SearchBox.component";
import {CdkAccordion, CdkAccordionItem} from "@angular/cdk/accordion";
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";

type Group = 'DAY' | 'SWI' | 'MID' | 'NOC';

type DeskGroup = {
    name: Group;
    disciplines: {
        name: string;
        shifts: {
            name: string;
            desks: FedExShift[];
        }[]
    }[]
}

@Component({
    templateUrl: './FedExShiftSelectionDialog.component.html',
    styleUrls: ['./FedExShiftSelectionDialog.component.css'],
    standalone: true,
    imports: [SearchBoxComponent, NgClass, SwxModule, CdkAccordion, CdkAccordionItem, DialogComponent, DialogHeaderComponent, DialogContentComponent, DialogFooterComponent, CdkDrag, CdkDragHandle]
})
export class FedExShiftSelectionDialogComponent implements OnInit
{
    selectedIds = new Set<number>();
    searchTerm = '';
    
    groups: Group[] = ['DAY', 'SWI', 'MID', 'NOC'];
    selectedGroup: DeskGroup;
    deskGroups: DeskGroup[] = [];
    

    constructor(private shiftService: FedExShiftService,
                @Inject(DIALOG_DATA) private data,
                private dialogRef: DialogRef) {
        
    }
    
    // The desks are not grouped at the top level by shift name.
    getRecordsForGroup(group: Group, records: IResourceItemList<FedExShift>): FedExShift[] {
        switch (group) {
            case "DAY":
                return records.filter(record => record.Name === "DAY");
            case "SWI":
                return records.filter(record => record.Name === "SWI");
            case "MID":
                return records.filter(record => record.Name === "MID");
            case "NOC":
                return records.filter(record => record.Name !== "DAY" && record.Name !== "SWI" && record.Name !== "MID");
        }
    }
    
    ngOnInit() {
        this.shiftService.getAllShifts().then(shifts => {
            this.selectedIds = this.shiftService.getSelectedIds();
            
            const byGroup = this.groups.map(group => ({
                name: group,
                records: this.getRecordsForGroup(group, shifts)
            }))
            
            const byDiscipline = byGroup.map(group => ({
                name: group.name,
                disciplines: [...new Set(group.records.map(record => record.Discipline))].map(discipline => ({
                    name: discipline,
                    records: group.records.filter(record => record.Discipline === discipline)
                })),
            }))

            this.deskGroups = byDiscipline.map(g => ({
                name: g.name,
                disciplines: g.disciplines.map(discipline => ({
                    name: discipline.name,
                    shifts: [...new Set(discipline.records.map(record => record.Name))].map(shift => ({
                        name: shift,
                        desks: discipline.records.filter(record => record.Name === shift)
                    }))

                }))
            }));
            
            this.selectedGroup = this.deskGroups[0];
        })
    }
    
    getLongShiftName(name: string): string {
        switch (name) {
            case 'D':
                return 'Day';
            case 'M':
                return 'MID';
            case 'S':
                return 'Swing';
            default:
                return name;
        }
    }
    
    selectDeskGroup(group: DeskGroup) {
        this.selectedGroup = group;
    }
    
    filteredDesks(desks: any): FedExShift[] {
        if (desks) {
            return desks.filter(d => d.Desk.includes(this.searchTerm))
        }
        return []
    }
    
    close: () => any;

    toggleShift(evt: any, shift: FedExShift) {
        if (evt.target.tagName === 'INPUT') {
            if (evt.target.checked) {
                this.selectedIds.add(shift.Id)
            }
            else {
                this.selectedIds.delete(shift.Id);
            }
        }
    }

    isSelected(shift: FedExShift): boolean {
        return this.selectedIds.has(shift.Id);
    } 

    confirmSelection() {
        this.shiftService.selectShifts(this.selectedIds);
        this.dialogRef.close();
    }
    
    cancelSelection() {
        this.dialogRef.close();
    }
    
    filterDesks(searchTerm: string) {
        this.searchTerm = searchTerm.toUpperCase().trim();
    }
}
