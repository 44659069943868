///<reference path="../../../node_modules/@types/google.visualization/index.d.ts"/>
import {Component, OnInit, OnDestroy, ElementRef, ViewContainerRef, Inject} from '@angular/core';
import moment from 'moment';
import { Subscription } from 'rxjs';
import jQuery from 'jquery';
import {
    ApiService,
    Fluid,
    DispatchAirport, 
    HotResponse,
    PortalMobileClientConfiguration,
    MobileWeatherInfo, SnowVisibilitiesMenuValue, SnowVisibilitiesMenuIntensity, FedExShift
} from '../shared/Api.service';
import { IResourceItemList, AuthenticationService, StorageService, IResourceItemMap, QuerySerializerService, SwxModule } from 'swx.front-end-lib';
import { RootScope } from '../shared/RootScope.service';
import { HasPermissionService, HasPermissionPipe } from '../shared/HasPermission.pipe';
import { HotBuilderService } from '../shared/HotBuilder.service';
import { FormatTemperaturePipe } from '../shared/FormatTemperature.pipe';
import { FluidSortPipe } from '../shared/FluidSort.pipe';
import { DispatchMonitorService, AirportAlert } from '../shared/DispatchMonitor.service';
import { WeatherTypeDialogComponent } from './WeatherTypeDialog.component';
import { IntensityDialogComponent } from './IntensityDialog.component';
import 'jquery-ui/ui/widgets/autocomplete.js';
import 'jquery-ui/ui/widgets/draggable.js';
import { NowcastService } from "../shared/Nowcast.service";
import { FedExShiftSelectionDialogComponent } from '../fedExShift/FedExShiftSelectionDialog.component';
import { FedExShiftService } from '../fedExShift/FedExShift.service';
import { DOCUMENT, NgFor, NgIf, AsyncPipe } from "@angular/common";
import {DispatchAirportGroup} from "./Dispatch.model";
import { GraphHots, HotGraphComponent } from "../hotGraph/HotGraph.component";
import { NowcastGraphComponent } from '../nowcastGraph/NowcastGraph.component';
import { HotRequestHistoryComponent } from '../hotRequestHistory/HotRequestHistory.component';
import { ThroughputGraphComponent } from '../throughputGraph/ThroughputGraph.component';
import { DispatchAirportWeatherIconComponent } from './DispatchAirportWeatherIcon.component';
import { DispatchAirportLegendComponent } from '../dispatchAirportLegend/DispatchAirportLegend.component';
import { DispatchAirportComponent } from './DispatchAirport.component';
import { FormsModule } from '@angular/forms';
import {Dialog, DialogRef} from "@angular/cdk/dialog";

@Component({
    templateUrl: 'Dispatch.component.html',
    standalone: true,
    imports: [SwxModule, NgFor, FormsModule, NgIf, DispatchAirportComponent, DispatchAirportLegendComponent, DispatchAirportWeatherIconComponent, ThroughputGraphComponent, HotGraphComponent, HotRequestHistoryComponent, NowcastGraphComponent, AsyncPipe, HasPermissionPipe, FormatTemperaturePipe]
})
export class DispatchComponent implements OnInit, OnDestroy {
    nowcastData$ = this.nowcastService.nowcastData$;

    moment = moment;
    Math = Math;
    selectedAirport: DispatchAirport;
    selectedAirportFluids: Array<Fluid>;
    latestHot: any = null;
    graphHots: IResourceItemMap<GraphHots>;
    fluids: IResourceItemList<Fluid>;
    graphHotsCount: number;
    airportSearch: any;
    hotResponse: HotResponse;
    maxHOTConfig = 120;
    maxRefMetarConfig = 120;
    hasTimer = false;
    showActiveFlights = false; // A flight is active if desk are showing and it has recent INITs (within 4h)

    holdoverTaxiTimeMessage: string = null;
    holdoverTaxiTimeLower?: number = null;
    holdoverTaxiTimeUpper?: number = null;

    throughputDatetimePickerOptions: any;
    throughputQuery = {
        Datetime: null
    };
    throughputSamples: any = null;
    throughputSamplesCount: number;
    throughputGraphOptions: google.visualization.ColumnChartOptions = {
        height: 600,
        chartArea: {
            width: '90%',
            bottom: 100,
            left: 100,
            right: 20,
            top: 20,
        },
        backgroundColor: {}
    };

    hotGraphOptions: google.visualization.LineChartOptions = {
        legend: {
            position: 'none'
        },
        height: 600,
        chartArea: {
            width: '90%',
            bottom: 100,
            left: 100,
            right: 0,
            top: 20,
        },
        backgroundColor: {}
    };

    airports: IResourceItemList<DispatchAirport>;
    airportsFilteredByDesk: IResourceItemList<DispatchAirport>;
    adhocAirports: Array<{ AirportId: number }> = [];
    assignedAirports = new Array<number>();
    
    selectedGroup: DispatchAirportGroup;
    alerts = new Array<AirportAlert>();
    hotRequests: any;
    hotRequestQuery = {
        HotRequestType: []
    };
    hotRequestTypes = {
        "API": "API",
        "Portal": "Portal",
        "ACARS": "ACARS",
        "MobileAPI": "MOBILE API",
        "XML": "XML",
    };

    dispatchMonitorAirportsSubscription: Subscription;

    manualEntryMode: boolean;
    portalMobileClient: PortalMobileClientConfiguration;
    selectedWeatherMenuItem?: MobileWeatherInfo;
    selectedVisibility?: SnowVisibilitiesMenuValue;
    selectedIntensity?: SnowVisibilitiesMenuIntensity;
    temperature: number = null;
    minTemp: number;

    showMetarRefHot = this.$root.currentClient.ShowMetarRefHot;
    showShiftSelection = this.$root.currentClient.ShowShiftSelection;
    selectedShifts: Array<FedExShift> = [];
    shiftSelectionDialogRef!: DialogRef;
    shiftSelectionDialogSub!: Subscription;
    intensityDialogRef: DialogRef<{visibility: SnowVisibilitiesMenuValue, intensity: SnowVisibilitiesMenuIntensity},IntensityDialogComponent>;
    intensityDialogClosedSub: Subscription;
    weatherTypeDialogRef: DialogRef<MobileWeatherInfo,WeatherTypeDialogComponent>;
    weatherTypeDialogClosedSub: Subscription;
    
    get selectedDeskNames() {
        const numberOfSelectedShifts = this.shiftService.getSelectedIds().size;
        
        if (this.numberOfDesks == null || numberOfSelectedShifts === 0)
            return '';
        if (numberOfSelectedShifts === this.numberOfDesks)
            return 'All Desks'
        return this.selectedShifts.map(s => s.Desk).join(', ')
    }

    get hideLeftPane() {
        return this.$root.currentClient.HideTilesIfSingleAirport && this.airports?.length === 1;
    }

    numberOfDesks: number = null;

    constructor(
        private elementRef: ElementRef,
        private viewContainerRef: ViewContainerRef,
        private api: ApiService,
        public $root: RootScope,
        private hasPermissionService: HasPermissionService,
        //private dialogService: DialogService,
        private authentication: AuthenticationService,
        public dispatchMonitor: DispatchMonitorService,
        private nowcastService: NowcastService,
        private querySerializer: QuerySerializerService,
        public storage: StorageService,
        public shiftService: FedExShiftService,
        @Inject(DOCUMENT) private document: Document,
        private dialog: Dialog,
    ) {
        this.throughputDatetimePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
            timeFormat: 'HH:00'
        };
    }

    ngOnDestroy(): void {
        this.dispatchMonitorAirportsSubscription?.unsubscribe();
        
        if (this.shiftSelectionDialogSub) this.shiftSelectionDialogSub.unsubscribe();
        if (this.weatherTypeDialogClosedSub) this.weatherTypeDialogClosedSub.unsubscribe();
        if (this.intensityDialogClosedSub) this.intensityDialogClosedSub.unsubscribe();
    }

    ngOnInit(): void {
        this.fluids = this.api.Fluid.query();
        this.shiftService.numberOfDesks.then(n => this.numberOfDesks = n)
        
        var dispatchSearch = jQuery(this.elementRef.nativeElement).find('.dispatchSearch');

        this.portalMobileClient = this.api.PortalMobileClient.queryObject();

        var dispatchAirportsPromiseResolve;
        var dispatchAirportsPromise = new Promise<IResourceItemList<DispatchAirport>>((resolve) => {
            dispatchAirportsPromiseResolve = resolve;
        });
        var dispatchAirportsSetupPromise: Promise<IResourceItemList<DispatchAirport>>;
        
        var dispatchMonitorAirportsObservable;

        (new Promise<void>((resolve, reject) => {
            if (this.$root.currentClient.ShowShiftSelection && this.hasPermissionService.hasPermission('DeskSelection')) {
                this.shiftService.getShifts().then(shifts => {
                    dispatchMonitorAirportsObservable = this.dispatchMonitor.monitor();
                    this.showActiveFlights = true;
                    resolve();
                })
            }
            else {
                dispatchMonitorAirportsObservable = this.dispatchMonitor.monitor();
                resolve();
            }
        })).then(() => {
            this.dispatchMonitor.refresh();

            this.dispatchMonitorAirportsSubscription = dispatchMonitorAirportsObservable
                .subscribe(airports => {
                    this.airports = airports;
                    this.airportsFilteredByDesk = this.airportsByShifts(this.selectedShifts, airports);

                    if (this.hideLeftPane) {
                        this.selectedAirport = airports[0];
                    }
                    else {

                        var leftPane = jQuery(this.elementRef.nativeElement).find('.airports');
                        var rightPane = jQuery(this.elementRef.nativeElement).find('.details');
                        var border = jQuery(this.elementRef.nativeElement).find('.border');
                        var legend = jQuery(this.elementRef.nativeElement).find('dispatch-airport-legend');

                        var dispatchWidth = Math.min(window.innerWidth - 4, parseInt(localStorage.getItem('dispatchWidth') ?? '400'));

                        var applyWidth = (left) => {
                            leftPane.css({ width: left });
                            rightPane.css({ left: left + 4 });
                            legend.css({ left: left - 100 });
                            localStorage.setItem('dispatchWidth', left);
                            dispatchWidth = left;
                        }

                        if (dispatchWidth) {
                            applyWidth(dispatchWidth);
                            border.css({ left: dispatchWidth });
                        }

                        border.draggable({
                            axis: 'x',
                            drag: (e, ui) => {
                                ui.position.left = Math.min(window.innerWidth - 4, ui.position.left);
                                applyWidth(ui.position.left);
                                jQuery(window).trigger('resize');
                            }
                        });

                        var resizeTimer;
                        jQuery(window).resize(() => {
                            clearTimeout(resizeTimer);
                            resizeTimer = setTimeout(() => {
                                if (dispatchWidth && dispatchWidth > window.innerWidth) {
                                    dispatchWidth = window.innerWidth - 20;
                                    border.css({ left: dispatchWidth });
                                    applyWidth(dispatchWidth);
                                }
                            }, 250);
                        });

                        // HACK to position search box
                        setTimeout(() => {
                            dispatchSearch.css('left', jQuery('#navigationBar .navbar-nav').width() + 25);
                        }, 100);
                    }

                    if (this.selectedAirport != null && !this.manualEntryMode) {
                        this.selectedAirport = this.airports.find(a => a.ICAOCode === this.selectedAirport.ICAOCode);
                        if (this.selectedAirport) this.refreshSelectedAirport();
                    }

                    this.alerts.length = 0;

                    dispatchAirportsPromiseResolve(airports);

                    dispatchAirportsSetupPromise.then(() => {
                        this.dispatchMonitor.alerts.forEach(alert => {
                            if (this.$root.currentUser.DispatchAirportProfile == null
                                || this.assignedAirports.some(i => i === alert.Airport.Id as any)
                                || this.adhocAirports.some(a => a.AirportId === alert.Airport.Id as any)) {
                                this.alerts.push(alert);
                            }
                        });
                    });

                });

            dispatchAirportsSetupPromise = dispatchAirportsPromise
                .then(airports => {
                    if (this.$root.currentUser.DispatchAirportProfile != null) {
                        this.assignedAirports = this.$root.currentUser.DispatchAirportProfile.DispatchAirportGroups
                            .map(g => g.DispatchAirportGroupSubgroups
                                .map(sg => sg.DispatchAirportGroupSubgroupAirports
                                    .map(sga => sga.AirportId))
                                .reduce((a, b) => a.concat(b), []))
                            .reduce((a, b) => a.concat(b), []);
                    }

                    let airportCodes: Array<{id: number, label: string}> = [];
                    const codes = airports.map(item => ({
                        id: item.Id,
                        label: this.$root.currentUser.UseIataCode ? (item.IATACode + '/' + item.ICAOCode) : (item.ICAOCode + '/' + item.IATACode)
                    }));
                    airportCodes = [...codes].sort((a, b) => a.label.localeCompare(b.label));
                    
                    var dispatchAdhocAirports = localStorage.getItem(this.authentication.getSessionId() + '_dispatchAdhocAirports');
                    if (dispatchAdhocAirports != null) {
                        this.adhocAirports = JSON.parse(dispatchAdhocAirports).filter(aid => airports.some(a => a.Id === aid as any)).map(aid => ({ AirportId: aid }));
                    }

                    var wasOpen = false;

                    var input = dispatchSearch.find('[name=airportSearch]');

                    input.autocomplete({
                        delay: 0,
                        minLength: 0,
                        source: (request, responseCallback) => responseCallback(airportCodes
                            .filter(o => {
                                return !request.term || o.label.toLowerCase().indexOf(request.term.toLowerCase()) !== -1;
                            })),
                        classes: {
                            'ui-autocomplete': 'autocomplete-component'
                        },
                        select: (e, ui) => {
                            if (!this.adhocAirports.some(a => a.AirportId === ui.item.id as any)) {
                                this.adhocAirports.push({
                                    AirportId: ui.item.id
                                });
                            }

                            this.selectAirport(airports.find(a => a.Id === ui.item.id));

                            localStorage.setItem(this.authentication.getSessionId() + '_dispatchAdhocAirports', JSON.stringify(this.adhocAirports.map(a => a.AirportId)));

                            e.preventDefault();
                            this.airportSearch = null;
                            setTimeout(() => {
                                input.val('');
                            });
                        },
                        close: () => {
                            setTimeout(() => {
                                this.airportSearch = null;
                                input.val('');
                            }, 1500);
                        }
                    });

                    dispatchSearch
                        .on('mousedown', () => wasOpen = input.autocomplete('widget').is(':visible'))
                        .on('click', () => {
                            input.trigger('focus');
                            if (wasOpen) return;
                            input.autocomplete('search', '');
                        });

                    return airports;
                });



            if (this.$root.currentUser.DispatchAirportProfile != null) {
                if (location.hash) {
                    this.selectedGroup = this.$root.currentUser.DispatchAirportProfile.DispatchAirportGroups
                        .find(g => location.hash.substring(1) === 'group_' + g.Id);
                } else {
                    this.selectedGroup = this.$root.currentUser.DispatchAirportProfile.DispatchAirportGroups
                        .sort(g => g.Order)[0];
                    if (this.selectedGroup != null) {
                        this.switchGroup(this.selectedGroup);
                    }
                }
            }
        })
    }

    alertsForDisplayedAirports(alerts: AirportAlert[]) {
        return alerts.filter(alert => 
            this.$root.currentUser.DispatchAirportProfile == null 
            || (this.selectedGroup != null && this.selectedGroup.DispatchAirportGroupSubgroups.some(subgroup => subgroup.DispatchAirportGroupSubgroupAirports.some(sga => this.airportsFilteredByDesk.some(afd => (afd.Id === sga.AirportId) && (sga.AirportId === alert.Airport.Id)))))
            || this.adhocAirports.some(a => a.AirportId === alert.Airport.Id as any)
        );
    }

    removeAdhocAirport(airport: DispatchAirport) {
        this.adhocAirports.splice(this.adhocAirports.findIndex(a => a.AirportId === airport.Id as any), 1);
        localStorage.setItem(this.authentication.getSessionId() + '_dispatchAdhocAirports', JSON.stringify(this.adhocAirports.map(a => a.AirportId)));
    }

    switchGroup(group: DispatchAirportGroup) {
        location.hash = 'group_' + group.Id;
        this.selectedGroup = group;
    };

    selectAirport(airport) {
        this.manualEntryMode = false;
        this.selectedAirport = airport;
        this.airportSearch = null;
        this.holdoverTaxiTimeMessage = null;
        this.holdoverTaxiTimeLower = null;
        this.holdoverTaxiTimeUpper = null;

        this.refreshSelectedAirport();
    }

    manualRefresh() {
        this.manualEntryMode = false;
        this.refresh();
    }

    refresh() {
        this.dispatchMonitor.refresh();
        if (this.selectedAirport && !this.manualEntryMode) {
            this.refreshSelectedAirport();
        }
    }

    removeAlert(airportId) {
        this.dispatchMonitor.removeAlert(airportId);
        var index = this.alerts.findIndex(a => a.Airport.Id === airportId as any);
        if (index !== -1) {
            this.alerts.splice(index, 1);
        }
    }

    refreshSelectedAirport() {
        this.graphHots = null;
        this.selectedAirportFluids = null;
        this.throughputSamples = [];

        var airportCode = this.$root.currentUser.UseIataCode ? this.selectedAirport.IATACode : this.selectedAirport.ICAOCode;
        this.hotResponse = JSON.parse(JSON.stringify(this.selectedAirport.HotResponse));

        var maxHot = Math.max.apply(null, this.hotResponse.HotResponseHots
            .map(h => h.MaxHot > h.MinHot ? h.MaxHot : h.MinHot));
        const maxRefMetarHot = Math.max.apply(null, this.hotResponse.HotResponseHots
            .map(h => h.ReferenceMetarMaxHot > h.ReferenceMetarMinHot ? h.ReferenceMetarMaxHot : h.ReferenceMetarMinHot));

        this.maxHOTConfig = maxHot === 0 ? 120 : maxHot;
        this.maxRefMetarConfig = maxRefMetarHot === 0 ? 120 : maxRefMetarHot;
        this.hasTimer = maxHot !== 0;

        this.holdoverTaxiTimeMessage = this.hotResponse.HoldoverTaxiTimeMessage;
        this.holdoverTaxiTimeLower = this.hotResponse.HoldoverTaxiTimeLower;
        this.holdoverTaxiTimeUpper = this.hotResponse.HoldoverTaxiTimeUpper;

        var fluidsPromise = this.fluids.$promise.then(() => {
            return this.selectedAirportFluids = FluidSortPipe.sort(this.fluids.filter(f => f.Airports.some(a => a === this.selectedAirport.Id as any)));
        });

        if (this.hasPermissionService.hasPermission('DispatchHotGraph')) {
            this.graphHotsCount = this.$root.currentClient.PortalHotGraphDuration / this.$root.currentClient.PortalHotGraphAveragingPeriod;

            fluidsPromise.then(() => {
                this.graphHots = this.api.ClientPortalHotGraphHot.queryMap<GraphHots>({
                    ClientId: this.$root.currentClient.Id,
                    AirportCode: airportCode,
                    Count: this.graphHotsCount,
                    Interval: this.$root.currentClient.PortalHotGraphAveragingPeriod,
                    PortalUserId: this.$root.currentUser.UserId,
                });
            });
        }

        if (this.hasPermissionService.hasPermission('NowcastGraph')) {
            this.nowcastService.update(this.selectedAirport);
        };

        this.throughputQueryChanged();
        this.hotRequestQueryChanged();
    }

    throughputQueryChanged() {
        if (this.hasPermissionService.hasPermission('DispatchThroughputGraph')
            && this.selectedAirport.DispatchThroughputGraph) {
            this.throughputSamplesCount = this.$root.currentClient.DispatchThroughputGraphDuration / this.$root.currentClient.DispatchThroughputGraphAveragingPeriod;

            this.throughputSamples = this.api.ThroughputGraphSample.query(this.querySerializer.toHttpParams(Object.assign(this.throughputQuery, {
                AirportId: this.selectedAirport.Id,
            })));
        }
    }

    hotRequestQueryChanged() {
        if (this.hasPermissionService.hasPermission('DispatchHotRequestHistory')) {
            var airportCode = this.$root.currentUser.UseIataCode ? this.selectedAirport.IATACode : this.selectedAirport.ICAOCode;

            this.api.ClientPortalHotRequest.query(this.querySerializer.toHttpParams(Object.assign(this.hotRequestQuery, {
                AirportCode: airportCode
            }))).$promise.then(items => {
                //console.log('before filtering hots', items)
                let filtered = this.filterHots(this.selectedShifts, this.selectedAirport.Id, items)
                //console.log('after filtering hots', filtered)
                if (this.showActiveFlights)
                    filtered = filtered.filter(h => h.HasInit) as IResourceItemList<any>
                this.hotRequests = filtered;
            })
        }
    }
    
    toggleActiveFlights(event: Event) {
        this.showActiveFlights = (<HTMLInputElement>event.target).checked;
        this.hotRequestQueryChanged();
    }

    isEmpty(value) {
        return value === null || typeof value === 'undefined' || value === '';
    }

    getHotStyle(hot): any {
        var targetLeftPercent = 50; // hack
        var targetRightPercent = 80; // to review
        var max = Math.max.apply(null, [hot.MinHot, hot.MaxHot]);
        var useStyle = Math.floor((max * 100) / this.maxHOTConfig) >= targetLeftPercent;

        var style = { min: {}, max: {}, separator: " - " };
        var leftWidth = Math.floor((hot.MinHot * 100) / this.maxHOTConfig);
        var rightWidth = (Math.floor((hot.MaxHot * 100) / this.maxHOTConfig)) - leftWidth;

        if (useStyle) {
            style.separator = null;

            style.min = {
                'display': 'inline-block',
                'width': (leftWidth - (leftWidth > targetRightPercent && hot.MaxHot ? leftWidth - targetRightPercent : 0)),
                'text-align': 'right',
                'float': 'left'
            },
                style.max = {
                    'display': 'inline-block',
                    'width': (rightWidth + (leftWidth > targetRightPercent && hot.MaxHot ? leftWidth - targetRightPercent : 0)),
                    'text-align': 'right',
                    'float': 'left'
                }
        }

        return style;
    }

    getRefMetarStyle(hot): any {
        const targetLeftPercent = 50; // hack
        const targetRightPercent = 80; // to review
        const max = Math.max.apply(null, [hot.ReferenceMetarMinHot, hot.ReferenceMetarMaxHot]);
        const useStyle = Math.floor((max * 100) / this.maxRefMetarConfig) >= targetLeftPercent;

        const style = { min: {}, max: {}, separator: " - " };
        const leftWidth = Math.floor((hot.ReferenceMetarMinHot * 100) / this.maxRefMetarConfig);
        const rightWidth = (Math.floor((hot.ReferenceMetarMaxHot * 100) / this.maxRefMetarConfig)) - leftWidth;

        if (useStyle) {
            style.separator = null;

            style.min = {
                'display': 'inline-block',
                'width': (leftWidth - (leftWidth > targetRightPercent && hot.ReferenceMetarMaxHot ? leftWidth - targetRightPercent : 0)),
                'text-align': 'right',
                'float': 'left'
            },
                style.max = {
                    'display': 'inline-block',
                    'width': (rightWidth + (leftWidth > targetRightPercent && hot.ReferenceMetarMaxHot ? leftWidth - targetRightPercent : 0)),
                    'text-align': 'right',
                    'float': 'left'
                }
        }

        return style;
    }

    formatFluidType(fluidType) {
        if (typeof fluidType === 'string' && fluidType.toLowerCase().indexOf('type') === 0) {
            fluidType = parseInt(fluidType.substring(4));
        }

        return fluidType;
    }

    mapAdhocAirports(subgroupAirports) {
        if (this.airports == null || this.airports.length === 0) return [];
        return subgroupAirports
            .map(sga => this.airports.find(a => a.Id === sga.AirportId))
            .filter(a => typeof a !== 'undefined');
    }

    mapAirports(subgroupAirports) {
        if (this.airportsFilteredByDesk == null || this.airportsFilteredByDesk.length === 0) return [];
        return subgroupAirports
            .map(sga => this.airportsFilteredByDesk.find(a => a.Id === sga.AirportId))
            .filter(a => typeof a !== 'undefined');
    }
    
    goOffline() {
        this.manualEntryMode = true;

        this.clearOfflineHot();

        this.selectedIntensity = null;
        this.selectedVisibility = null;
        this.selectedWeatherMenuItem = null;

        var temperature = this.hotResponse.Temperature;
        this.minTemp = -49;
        if (this.$root.currentUser.UseFahrenheit) {
            temperature = FormatTemperaturePipe.celsiusToFahrenheit(temperature);
            this.minTemp = Math.floor(FormatTemperaturePipe.celsiusToFahrenheit(this.minTemp));
        }

        this.temperature = Math.floor(temperature);
    }

    editWeatherType() {
        this.goOffline();
    }

    clearOfflineHot() {
        this.hotResponse.HotResponseHots.forEach(hrh => {
            hrh.MinHot = null;
            hrh.MaxHot = null;
            hrh.NonHotMessage = null;
        });
    }

    private getSelectedMetarWeatherType() {
        return this.selectedWeatherMenuItem?.MetarWeatherTypeId
            ?? this.selectedVisibility?.MetarWeatherType
            ?? this.selectedIntensity?.MetarWeatherType;
    }

    getManualHotResponse() {
        this.clearOfflineHot();

        var selectedMetarWeatherType = this.getSelectedMetarWeatherType();

        if (selectedMetarWeatherType != null && this.manualEntryMode) {
            this.hotResponse.HotResponseHots.forEach(hrh => {
                var clientFluid = this.portalMobileClient.MobileClient.ClientFluids.find(cf => cf.FluidId === hrh.FluidId);

                var temperature = this.temperature;
                if (this.$root.currentUser.UseFahrenheit) {
                    temperature = FormatTemperaturePipe.fahrenheitToCelsius(temperature);
                }

                var offlineHot = HotBuilderService.getManualHot(
                    this.portalMobileClient.MobileClient,
                    selectedMetarWeatherType,
                    temperature,
                    clientFluid,
                    this.portalMobileClient.FluidSpecs,
                    this.portalMobileClient.MessageMappings,
                );

                hrh.MinHot = offlineHot.MinHot;
                hrh.MaxHot = offlineHot.MaxHot;
                hrh.NonHotMessage = offlineHot.holdoverTime;
            });

            var maxHot = Math.max.apply(null, this.hotResponse.HotResponseHots
                .map(h => h.MaxHot > h.MinHot ? h.MaxHot : h.MinHot));

            this.maxHOTConfig = maxHot === 0 ? 120 : maxHot;
            this.hasTimer = maxHot !== 0;
        }
    }
    
    showWeatherTypePopup() {
        this.portalMobileClient.$promise.then(() => {
            this.weatherTypeDialogRef = this.dialog.open(WeatherTypeDialogComponent,
                {
                    data: {
                        title: 'Select Precipitation',
                        selectedWeatherMenuItem: this.selectedWeatherMenuItem,
                        weatherTypes: this.portalMobileClient.Weathers,
                    },
                    width: '400px',
                    height: '600px',
                });
            this.weatherTypeDialogClosedSub = this.weatherTypeDialogRef.closed.subscribe(weatherMenuItem => {
                if (weatherMenuItem !== null) {
                    this.selectedIntensity = null;
                    this.selectedVisibility = null;
                    this.selectedWeatherMenuItem = weatherMenuItem;
                    this.getManualHotResponse();
                }
            })
        });
    }
    
    showIntensityPopup() {
        const selectedTemperatureOption = this.selectedWeatherMenuItem.SnowVisibilityMenu.TemperatureOptions
            .filter(x => this.temperature <= x.MaxTempC)
            .sort((a, b) => a.MaxTempC > b.MaxTempC ? 1 : -1)[0];

        this.intensityDialogRef = this.dialog.open(IntensityDialogComponent,
            {
                data: {
                    title: 'Select intensity',
                    selectedIntensity: this.selectedIntensity,
                    selectedVisibility: this.selectedVisibility,
                    selectedTemperatureOption: selectedTemperatureOption,
                    selectedDayNightOption: selectedTemperatureOption.DayNightOptions[0],
                    snowVisibilities: this.selectedWeatherMenuItem.SnowVisibilityMenu,
                },
                width: '400px',
                height: '300px',
            });

        this.intensityDialogClosedSub = this.intensityDialogRef.closed.subscribe(result => {
            if (result) {
                this.selectedVisibility = result.visibility;
                this.selectedIntensity = result.intensity;
                this.getManualHotResponse();
            }
        })
    }
    
    
    showShiftSelectionPopup(onShiftsSelected: () => any) {
        this.shiftSelectionDialogRef = this.dialog.open(FedExShiftSelectionDialogComponent, {
            width: '650px',
            height: '60vh',
        });
        
        this.shiftSelectionDialogRef.closed.subscribe(() => onShiftsSelected());
    }


    changeShift() {
        this.showShiftSelectionPopup(() => {
            this.shiftService.getShifts().then(shifts => {
                //console.log('getShifts in changeShift callback: shifts=', shifts);
                this.selectedShifts = shifts;
                //console.log('refreshing monitor');
                this.dispatchMonitor.refresh();
            });
        })
    }

    private getShiftsAirports(shifts: FedExShift[]): Set<number> {
        const airportIds = shifts.flatMap(s => s.Flights).flatMap(f => [f.OriginAirportId, f.DestinationAirportId]);
        return new Set(airportIds);
    }

    // If there's a Shift, filters the airports to the Shift's airports
    private airportsByShifts(shifts: FedExShift[], airports: IResourceItemList<DispatchAirport>) {
        if (shifts.length > 0) {
            const shiftAirports = this.getShiftsAirports(shifts);
            return airports.filter(a => shiftAirports.has(a.Id)) as IResourceItemList<DispatchAirport>;
        }

        return airports;
    }

    // If there's a Shift, filters ClientPortalHot to those with flights on the Shift
    // HOTs won't be filtered for an adHoc airport
    private filterHots(shifts: FedExShift[], selectedAirportId: number, clientPortalHots: IResourceItemList<any>): IResourceItemList<any> {
        const adHocAirportIds = new Set<number>(this.adhocAirports.map(aha => aha.AirportId));
        if (adHocAirportIds.has(selectedAirportId))
            return clientPortalHots;
        
        const tailNumeric = (tailNumber: string) => /\S*?(\d+)\S*/.exec(tailNumber)?.[1] || tailNumber;
        
        if (shifts.length > 0) {
            const flightTailsByShift = (shifts: FedExShift[]) => new Set(shifts
                .flatMap(s => s.Flights)
                .filter(f => f.OriginAirportId === selectedAirportId || f.DestinationAirportId === selectedAirportId)
                .map(f => f.TailNumber));
            return clientPortalHots.filter(hot => flightTailsByShift(shifts).has(tailNumeric(hot.TailNumber))) as IResourceItemList<any>;
        }

        return clientPortalHots;
    }

}
