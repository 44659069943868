import { Component, Input } from '@angular/core';
import moment from 'moment';
import { RootScope } from '../shared/RootScope.service';
import { Fluid, HotResponseHot } from '../shared/Api.service';
import { FormatTemperaturePipe } from '../shared/FormatTemperature.pipe';
import { SwxModule } from 'swx.front-end-lib';
import { NgFor } from '@angular/common';

@Component({
    selector: 'hot-response',
    templateUrl: 'HotResponse.component.html',
    standalone: true,
    imports: [NgFor, SwxModule, FormatTemperaturePipe]
})
export class HotResponseComponent {
    moment = moment;
    @Input('data') latestHot: any = {};
    @Input('fluids') fluids: Array<Fluid>;

    constructor(
        public $root: RootScope,
    ) {
    }

    findHot(hotResponseHots: Array<HotResponseHot>, fluid: Fluid): HotResponseHot|undefined {
        return (hotResponseHots || []).find(h => h.FluidId === fluid.Id);
    }
}
