<form #form #ngForm="ngForm" (submit)="save()">
    <div class="noMargins contentWrapper">
        <div class="toolbar">
            <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
            <a class="tab" (click)="switchTab('groups')" [class.active]="tab == 'groups'" [class.invalid]="!ngForm.form.controls.groupsForm?.valid">{{'Groups'|translate}}</a>
            <label class="inline" *ngIf="tab == 'groups'">
                <span class="caption">{{'Search'|translate}}</span>
                <input [(ngModel)]="searchQuery" name="searchQuery" />
            </label>
            <label>
                <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
            </label>
            <label>
                <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
            </label>
        </div>

        <fieldset [disabled]="!('DispatchConfiguration'|hasPermission)" class="contentMargins form" [style.display]="tab == 'basicInfo' ? '' :'none'" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'Name'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Name" name="Name" />
            </label>
            <label>
                <span class="caption">{{'Default configuration'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Default" name="Default" />
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="Active" />
            </label>
            <label *ngIf="$root.currentClient.PortalUseSaml2Authentication">
                <span class="caption">{{'SAML 2.0 mapping attribute name'|translate}}</span>
                <input type="text" [required]="item.PortalSaml2Value != null && item.PortalSaml2Value != ''" [(ngModel)]="item.PortalSaml2Attribute" name="PortalSaml2Attribute" />
            </label>
            <label *ngIf="$root.currentClient.PortalUseSaml2Authentication">
                <span class="caption">{{'SAML 2.0 mapping attribute value'|translate}}</span>
                <input type="text" [required]="item.PortalSaml2Attribute != null && item.PortalSaml2Attribute != ''" [(ngModel)]="item.PortalSaml2Value" name="PortalSaml2Value" />
            </label>
        </fieldset>

        <fieldset [disabled]="!('DispatchConfiguration'|hasPermission)" class="form airportGroups" [style.display]="tab == 'groups' ? '' :'none'" ngModelGroup="groupsForm">
            <div class="groups">
                <sortable class="items" [options]="{ placeholder: 'placeholder item' }">
                    <sortable-item *ngFor="let group of item.DispatchAirportGroups | orderBy:'Order'" [(ngModel)]="group.Order" name="group.Order[{{group.Id ?? group.TmpKey}}]">
                        <div class="item group">
                            <label class="dragIcon"><span class="mdi mdi-adjust"></span></label>
                            <label class="groupActions">
                                <a *ngIf="('DispatchConfiguration'|hasPermission)" (click)="removeGroup(group);" class="mdi mdi-delete"></a>
                            </label>
                            <label class="groupName"><input type="text" required [(ngModel)]="group.Name" name="group.Name[{{group.Id ?? group.TmpKey}}]" /></label>
                        </div>
                        <div class="items">
                            <sortable [options]="{ placeholder: 'placeholder item' }">
                                <sortable-item class="item subgroup" *ngFor="let subgroup of group.DispatchAirportGroupSubgroups | orderBy:'Order'" [(ngModel)]="subgroup.Order" name="subgroup.Order[{{subgroup.Id ?? subgroup.TmpKey}}]" (click)="selectedSubgroup = subgroup" [class.selected]="selectedSubgroup == subgroup">
                                    <label class="dragIcon"><span class="mdi mdi-adjust"></span></label>
                                    <label class="groupActions">
                                        <a *ngIf="('DispatchConfiguration'|hasPermission)" (click)="removeSubgroup(subgroup, group);" class="mdi mdi-delete"></a>
                                    </label>
                                    <label class="groupName"><input type="text" required [(ngModel)]="subgroup.Name" name="subgroup.Name[{{subgroup.Id ?? subgroup.TmpKey}}]" /></label>
                                </sortable-item>
                            </sortable>
                            <input type="button" *ngIf="('DispatchConfiguration'|hasPermission)" (click)="addSubgroup(group);" value="{{'Add subgroup'|translate}}"/>
                        </div>
                    </sortable-item>
                </sortable>
                <input type="button" *ngIf="('DispatchConfiguration'|hasPermission)" (click)="addGroup();" value="{{'Add group'|translate}}"/>
            </div>
            <div class="airports">
                <div class="airport" *ngFor="let airport of airports|filter: searchQuery|orderBy:'ICAOCode'" (click)="toggleAirport(selectedSubgroup, airport)" [class.selected]="selectedSubgroup != null && doesSubgroupContainAirport(selectedSubgroup, airport)">
                    <div class="airportCodeWrapper"> 
                        <input type="checkbox" [disabled]="selectedSubgroup == null" [checked]="selectedSubgroup != null && doesSubgroupContainAirport(selectedSubgroup, airport)" />
                        <span class="airportCode">{{$root.currentUser.UseIataCode ? airport.IATACode : airport.ICAOCode}}</span>
                    </div>
                    {{getSubgroupNamesWithAirport(airport)}}
                </div>
            </div>
        </fieldset>
    </div>
</form>
