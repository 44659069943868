import { Component, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';
import { ApiService } from '../shared/Api.service';
import jQuery from 'jquery';
import { GridComponent, SwxModule, GridModule } from "swx.front-end-lib";
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    templateUrl: 'LWEGrid.component.html',
    standalone: true,
    imports: [
        SwxModule,
        FormsModule,
        NgIf,
        NgFor,
        GridModule,
    ],
})
export class LWEGridComponent implements OnInit {
    @ViewChild('grid', { static: true }) grid: GridComponent;
    stations = this.api.Station.query();
    sensorSelectMatrices = this.api.SensorSelectMatrix.query();
    exportResourcePost = this.api.WeatherProfileExport;
    datePickerOptions: any;
    query: any;
    columnDefs = [
        { colId: "WeatherProfile.Id", field: "WeatherProfile.Id", headerName: "#", width: 90, filterType: "integer" },
        { colId: "DateTimeDate", field: "DateTime", headerName: "Date", width: 90, valueFormatter: c => c.value ? moment.utc(c.value).format('YYYY-MM-DD') : "", "pinnedLeft": true, filterType: "date" },
        { colId: "DateTimeTime", field: "DateTime", headerName: "Time", width: 60, valueFormatter: c => c.value ? moment.utc(c.value).format('HH:mm') : "", excelIgnore: true, "pinnedLeft": true, "searchable": false },
        { colId: "StationName", field: "StationName", headerName: "Station", width: 80, "pinnedLeft": true },
        { colId: "SensorSelectMatrixName", field: "SensorSelectMatrixName", headerName: "Sensor select matrix", width: 200, "pinnedLeft": true },
        
        { colId: "LWEWithoutOperationalTolerance", field: "LWEWithoutOperationalTolerance", headerName: "LWE without operational tolerance (g/dm²/h)", width: 160, filterType: "float", "pinnedLeft": true },
        { colId: "WeatherProfile.LWE", field: "WeatherProfile.LWE", headerName: "LWE with operational tolerance (g/dm²/h)", width: 160, filterType: "float", "pinnedLeft": true },
        { colId: "WeatherProfile.Temperature", field: "WeatherProfile.Temperature", headerName: "Temperature (°C)", width: 110, filterType: "float", "pinnedLeft": true },
        { colId: "WeatherProfile.RelativeHumidity", field: "WeatherProfile.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 80, filterType: "float" },
        { colId: "WeatherProfile.DewPoint", field: "WeatherProfile.DewPoint", headerName: "Dew point (°C)", width: 80, filterType: "float" },
        { colId: "DewPointDifference", field: "DewPointDifference", headerName: "T - Dew point (°C)", width: 80, filterType: "float" },
        { colId: "WeatherProfile.FrostPoint", field: "WeatherProfile.FrostPoint", headerName: "Frost point (°C)", width: 80, filterType: "float" },
        { colId: "WeatherProfile.WindSpeed", field: "WeatherProfile.WindSpeed", headerName: "Wind speed (m/s)", width: 60, filterType: "float" },

        { colId: "WeatherProfile.MostRecentWeatherTypeNg", field: "WeatherProfile.MostRecentWeatherTypeNg", headerName: "Weather type", width: 120, "searchable": false },
    ];

    constructor(
        public api: ApiService,
    ) {
        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        };

        if (window.localStorage['lweGridQuery']) {
            this.query = JSON.parse(window.localStorage['lweGridQuery']);
        } else {
            this.resetQuery();
        }
    }

    ngOnInit() {
        this.grid.gridReadyPromise.then(() => {
            jQuery('.ag-paging-panel').prepend('<span class="notice">Strictly Confidential</span>');
        });
    }

    resetQuery() {
        this.query = {
            StationId: [],
            SensorSelectMatrixId: [],
            FromDate: null,
            ToDate: null,
            Filters: []
        };
    };
    
    hasRequiredFilters() {
        return Object.keys(this.query).filter((item) => {
            return (Array.isArray(this.query[item]) && this.query[item].length > 0) || this.query[item] !== null;
        }).length > 0;
    }

    refresh() {
        window.localStorage['lweGridQuery'] = JSON.stringify(this.query);
        this.grid.refresh();
    }
}
