import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DispatchAirport } from '../shared/Api.service';
import { RootScope } from '../shared/RootScope.service';
import { FormatTemperaturePipe } from '../shared/FormatTemperature.pipe';
import { HasPermissionPipe } from '../shared/HasPermission.pipe';
import { SwxModule } from 'swx.front-end-lib';
import { DispatchAirportWeatherIconComponent } from './DispatchAirportWeatherIcon.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'dispatch-airport',
    templateUrl: 'DispatchAirport.component.html',
    standalone: true,
    imports: [NgIf, DispatchAirportWeatherIconComponent, SwxModule, HasPermissionPipe, FormatTemperaturePipe]
})
export class DispatchAirportComponent {
    @Input('airport') airport: DispatchAirport;
    @Input('selected') selected: boolean;
    @Input('adhoc') adhoc: boolean;
    @Output() remove = new EventEmitter();

    constructor(
        public $root: RootScope,
    ) {
    }
}