<div style="display: flex; flex-direction: column; height: 100%;">
    <div class="toolbar">
        <search-box (search)="filterDesks($event)"></search-box>
    </div>
    <div class="toolbar">
    @for(shift of shiftsByShiftName; track shift.name) {
        <a class="tab"
           [ngClass]="{active: shift.name === selectedShift.name}"
           (click)="selectShift(shift)">
            {{shift.name}}
        </a> 
    }
    </div>
    <div style="flex: 1; overflow-y: scroll;" class="container">
        @for(shiftByDiscipline of selectedShift?.shifts; track shiftByDiscipline.discipline) {
            <h3 class="discipline">{{shiftByDiscipline.discipline}}</h3>
            <ul>
            @for(desk of filteredDesks(shiftByDiscipline.shifts); track desk.Id) {
                <li>
                    <label (click)="toggleShift($event, desk)">
                        <input type="checkbox" [checked]="isSelected(desk)"/>
                        {{desk.Desk}}
                    </label>
                </li>
            }
            </ul>
        }
    </div>
    <hr />
    <div class="buttons">
        <button class="btn" (click)="cancelSelection()"><span class="mdi mdi-close">{{'Cancel'|translate}}</span></button>
        <button class="btn" (click)="confirmSelection()"><span class="mdi mdi-check"></span> {{'Confirm'|translate}}</button>
    </div>
</div>

