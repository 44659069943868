import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from "./environments/environment";
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { ApiService } from './shared/Api.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { DispatchMonitorService } from './shared/DispatchMonitor.service';
import { UnknownComponent } from './unknown/Unknown.component';
import { IndexComponent } from './index/Index.component';
import { LWEGridComponent } from './lwe/LWEGrid.component';
import { MobileAppDownloadComponent } from './mobileAppDownload/MobileAppDownload.component';
import { EngineCoverAlertGridComponent } from './engineCoverAlert/EngineCoverAlertGrid.component';
import { DeIcingPortalComponent } from './deicingPortal/DeIcingPortal.component';
import { AnalyticsComponent } from './analytics/Analytics.component';
import { DispatchAirportProfileDetailComponent } from './dispatchAirportProfile/DispatchAirportProfileDetail.component';
import { DispatchAirportProfileListComponent } from './dispatchAirportProfile/DispatchAirportProfileList.component';
import { DispatchComponent } from './dispatch/Dispatch.component';
import { HotsComponent } from './hots/Hots.component';
import { Saml2ErrorComponent } from './login/Saml2Error.component';
import { UserProfileComponent } from './login/UserProfile.component';
import { PrivacyComponent } from './privacy/Privacy.component';
import { RegisterComponent } from './login/Register.component';
import { ConfirmComponent } from './login/Confirm.component';
import { ResetPasswordComponent } from './login/ResetPassword.component';
import { ForgotPasswordComponent } from './login/ForgotPassword.component';
import { LoginComponent } from './login/Login.component';
import { Routes, provideRouter } from '@angular/router';
import { AuthGuard } from './shared/AuthGuard.service';
import { AuthenticationService, AuthInterceptorService, ExportApi, GridModule, SwxModule, AuthModule } from 'swx.front-end-lib';
import { RootScope } from './shared/RootScope.service';
import { HasPermissionService } from './shared/HasPermission.pipe';
import { CLIENT_CODE } from './shared/ClientCode.const';
import { APP_BASE_HREF, DecimalPipe } from '@angular/common';

enum NavColor {
    green = '#2B5257',
    brown = '#603A2D'
}

const appRoutes: Routes = [{ 
        path: 'login', 
        component: LoginComponent,
        data: {
            title: 'Login'
        }
    },
    { 
        path: 'forgotPassword', 
        component: ForgotPasswordComponent,
        data: {
            title: 'Forgot Password'
        }
    },
    { 
        path: 'resetPassword/:id', 
        component: ResetPasswordComponent,
        data: {
            title: 'Reset Password'
        }
    },
    { 
        path: 'confirm/:id', 
        component: ConfirmComponent,
        data: {
            title: 'Login'
        }
    },
    { 
        path: 'register', 
        component: RegisterComponent,
        data: {
            title: 'Register'
        }
    },
    { 
        path: 'privacy', 
        component: PrivacyComponent,
        data: {
            title: 'Privacy Policy'
        }
    },
    { 
        path: 'userProfile', 
        component: UserProfileComponent, 
        canActivate: [AuthGuard],
        data: {
            title: 'Settings'
        }
    },
    { 
        path: 'saml2Error', 
        component: Saml2ErrorComponent 
    },
    { 
        path: 'hots', 
        component: HotsComponent, 
        canActivate: [AuthGuard],
        data: {
            title: 'Legacy<strong>PORTAL</strong>'
        }
    },
    { 
        path: 'dispatch', 
        component: DispatchComponent, 
        canActivate: [AuthGuard],
        data: {
            title: 'Dispatch<strong>PORTAL</strong>'
        }
    },
    { 
        path: 'dispatchAirportProfiles', 
        component: DispatchAirportProfileListComponent, 
        canActivate: [AuthGuard],
        data: {
            title: 'Dispatch<strong>PORTAL</strong>'
        }
    },
    { 
        path: 'dispatchAirportProfiles/:id', 
        component: DispatchAirportProfileDetailComponent, 
        canActivate: [AuthGuard],
        data: {
            title: 'Dispatch<strong>PORTAL</strong>'
        }
    },
    { 
        path: 'analytics/:id', 
        component: AnalyticsComponent, 
        canActivate: [AuthGuard],
        data: {
            title: 'Analytics<strong>PORTAL</strong>'
        }
    },
    {
        path: 'warn/:id',
        component: AnalyticsComponent,
        canActivate: [AuthGuard],
        data: {
            color: NavColor.brown,
            title: 'Sure<strong>WARN</strong>'
        }
    },
    { 
        path: 'deicingPortal', 
        component: DeIcingPortalComponent, 
        canActivate: [AuthGuard], 
        data: {
            title: 'De-Icing<strong>PORTAL</strong>'
        }
    },
	{
        path: 'engineCover', 
        component: EngineCoverAlertGridComponent, 
        canActivate: [AuthGuard],
        data: {
            color: NavColor.brown,
            title: 'Sure<strong>WARN</strong>'
        }
    },
	{ 
        path: 'mobileAppDownload',
        component: MobileAppDownloadComponent, 
        canActivate: [AuthGuard], 
        title: 'App Download' ,
        data: {
            title: 'Mobile App Download'
        }
    },
    // currently ignored and not visible from the UI
    {
        path: 'lwe',
        component: LWEGridComponent,
        canActivate: [AuthGuard],
        title: 'LWEs',
        data: {
            title: 'LWEs'
        }
    },
    
    { 
        path: '', 
        component: IndexComponent, 
        canActivate: [AuthGuard] 
    },
    { 
        path: '**', 
        component: UnknownComponent 
    }
];




if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, FormsModule, GridModule, SwxModule.forRoot(environment), AuthModule.forRoot({
            tokenUrl: `${environment.apiUrl}/oauth/access_token`,
            userUrl: `${environment.apiUrl}/user/me`,
            storageKey: 'access_token',
        })),
        { provide: APP_BASE_HREF, useValue: '/' + CLIENT_CODE },
        DecimalPipe,
        HasPermissionService,
        RootScope,
        AuthenticationService,
        AuthGuard,
        DispatchMonitorService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        {
            provide: ExportApi,
            useClass: ApiService,
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(appRoutes)
    ]
})
    .catch(err => console.error(err));
