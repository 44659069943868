import { Component, Input, OnInit, OnChanges, ViewChild, SimpleChanges, AfterViewInit, ElementRef } from '@angular/core';
import { GoogleChartsService } from 'swx.front-end-lib';

import jQuery from 'jquery';
import { NgIf } from '@angular/common';

/**
 * Prediction Data
 *
 * LeadTime: time in minutes until the start of the nowcasst
 * Prediciton: best guess the event will happen (ex. will there be heavy snow? 1=yes, is an all clear event 1=yes)
 * Confidence: measure of how certain we are of the prediction
 */
interface Prediction {
    LeadTime: number;
    Confidence: number;
    Prediction: number;
}


@Component({
    selector: 'nowcast-graph',
    templateUrl: './NowcastGraph.component.html',
    styleUrls: ['./NowcastGraph.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class NowcastGraphComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('chartEl', { static: false }) chartEl: ElementRef<HTMLElement>;
    @Input() data: Array<Prediction>;

    constructor(private googleChartsService: GoogleChartsService) {}

    columnChart: google.visualization.ColumnChart;

    chartOptions: google.visualization.ColumnChartOptions = {
        height: 500,
        hAxis: {
            title: 'Time in minutes until start',
            ticks: [],
            viewWindow: {
                min: 0,
                max: 240
            }
        },
        vAxis: {
            title: 'Confidence of moderate precipitation conditions',
            format: 'percent',
            viewWindow: {
                min: 0,
                max: 1
            }
        },
        legend: {
            position: 'none'
        }
    };

    ngOnInit(): void {
        let resizeTimer;
        // Redraw the chart on resizing
        jQuery(window).bind('resize',
            () => {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                        this.drawChart();;
                    },
                    250);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.googleChartsService.loadGoogleCharts().then(() => this.drawChart());
    }

    ngAfterViewInit() {
        this.googleChartsService.loadGoogleCharts().then(() => {
            this.columnChart = new google.visualization.ColumnChart(this.chartEl.nativeElement);
        });
    }

    // Construct the "DataTable" for the chart
    private loadChartData() {
        let dataTable = new google.visualization.DataTable();
        dataTable.addColumn('number', 'Lead Time');
        dataTable.addColumn('number', 'Confidence');

        dataTable.addRows(this.data.map(p => ([p.LeadTime, p.Confidence])));

        // Go with empty graph if there's no data
        if (dataTable.getNumberOfRows() < 2)
            dataTable.addRow([0, 0]);

        return dataTable;
    }

    private drawChart() {
        this.googleChartsService.loadGoogleCharts().then(() => {
            const dataTable = this.loadChartData();
            this.chartOptions.hAxis.ticks = this.data.map(p => p.LeadTime);
            this.columnChart.draw(dataTable, this.chartOptions);
        });

    }
}
