import {Component, OnDestroy, OnInit} from "@angular/core";
import {RootScope} from "../shared/RootScope.service";
import {MenuItem, MenuItemGroup} from "./MenuItem";
import { Router, RoutesRecognized, RouterLinkActive, RouterLink } from "@angular/router";
import {HasPermissionService} from "../shared/HasPermission.pipe";
import { AuthenticationService, SwxModule } from "swx.front-end-lib";
import {BehaviorSubject, combineLatest, Observable, Subscription} from "rxjs";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {AuthGuard} from '../shared/AuthGuard.service';
import { MenuComponent } from "./Menu.component";
import { NgStyle, NgIf, NgClass, NgFor, AsyncPipe } from "@angular/common";

@Component({
    selector: 'swx-navbar',
    templateUrl: './Navbar.component.html',
    standalone: true,
    imports: [
        NgStyle,
        NgIf,
        NgClass,
        NgFor,
        RouterLinkActive,
        RouterLink,
        SwxModule,
        MenuComponent,
        AsyncPipe,
    ],
})
export class NavbarComponent implements OnInit, OnDestroy {
    isMenuOpen = false;
    activeParent = null;
    activeItem: MenuItem = null;
    activeBackgroundColor = '#2B5257';
    private titleSubject = new BehaviorSubject<string>('');
    title$ = this.titleSubject.asObservable();
    itemGroups: MenuItemGroup[] = [];
    eventSub: Subscription;
    
    constructor(public $root: RootScope,
                private router: Router,
                private hasPermissionService: HasPermissionService,
                private authentication: AuthenticationService,
                private authGuard: AuthGuard,
                ) {
    }
    
    currentClient$: Observable<any> = fromPromise(this.$root.currentClient.$promise);
    userChange$ = this.authentication.userChange;
    clientAndUserSub = combineLatest([this.currentClient$, this.userChange$, this.title$]).subscribe(
        ([client, user, title]) => {
            this.itemGroups = [
                {
                    color: '#2B5257',
                    children: [
                        {
                            color: '#2B5257',
                            title: 'Legacy <strong>PORTAL</strong>',
                            route: ['/hots'],
                            permission: 'HOTs'
                        },
                        {
                            color: '#2B5257',
                            title: 'Dispatch<strong>PORTAL</strong>',
                            children: [
                                {
                                    title: 'Main screen',
                                    route: ['/dispatch'],
                                    permission: 'Dispatch'
                                },
                                {
                                    title: 'Configuration',
                                    route: ['/dispatchAirportProfiles'],
                                    permission: 'DispatchConfiguration'
                                }
                            ]
                        },
                        {
                            color: '#2B5257',
                            title: 'De-Icing<strong>PORTAL</strong>',
                            route: ['/deicingPortal'],
                            permission: 'DeIcingPortal'
                        },
                        {
                            color: '#2B5257',
                            title: 'Analytics<strong>PORTAL</strong>',
                            children: this.getDashboards(client.ClientPortalTableauDashboards, 'Analytics'),
                            permission: 'Analytics'
                        },
                        {
                            color: '#2B5257',
                            title: 'NCAR<strong>PORTAL</strong>',
                            route: ['/lwe'],
                            permission: 'LWEGrid'
                        }
                    ]
                },
                {
                    color: '#603A2D',
                    children: [
                        {
                            color: '#603A2D',
                            title: 'Sure<strong>WARN</strong>',
                            children: [...this.getDashboards(client.ClientPortalTableauDashboards, 'Warn'),
                                {
                                    title: 'Engine<strong>COVERS</strong>',
                                    route: ['/engineCover'],
                                    permission: 'EngineCoverAlerts'
                                }
                            ]
                        }
                    ]
                }
            ];
            
            this.itemGroups = this.itemGroups.map(group => ({
                ...group,
                children: group.children.map(child => {
                    if (child.permission && this.hasPermissionService.hasPermission(child.permission)) {
                        return child;
                    }
                    
                    const filtered = child.children?.filter(item => this.hasPermissionService.hasPermission(item.permission))
                    if (filtered?.length > 0) return ({
                        ...child,
                        children: filtered
                    });
                    
                    return null;
                }).filter(child => !!child)
            }))
            this.activeItem = this.itemGroups.flatMap(group => group.children).find(item => item.title === title)
        }
    )
    
    ngOnInit() {
        const eventSub = this.router.events.subscribe((e) => {
            if (e instanceof RoutesRecognized) {
                const childData = e.state.root.firstChild.data;
                this.titleSubject.next(childData['title'] || '');
                this.activeBackgroundColor = childData['color'] || '#2B5257';
                this.isMenuOpen = false;
            }
        })
    }
    
    ngOnDestroy() {
        this.clientAndUserSub.unsubscribe();
        this.eventSub.unsubscribe();
    }
    
    onItemSelected(e) {
        if (e.isTopLevel) {
            this.closeSubMenus()
            this.activeParent = e.item;
        }
        
        if (e.item.route) {
            this.closeSubMenus();
            this.isMenuOpen = false;
            this.router.navigate(e.item.route).then(() => {
                this.activeItem = this.activeParent;
            })
        }
        
        if (e.item.children) {
            e.item.isOpen = !e.item.isOpen;
        }
    }
    
    toggleMenu() {
        if (this.isMenuOpen) {
            this.closeSubMenus()
            this.isMenuOpen = false;
        }
        else {
            this.isMenuOpen = true;
        }
    }
    
    onBackdropClick() {
        this.closeSubMenus();
        this.isMenuOpen = false;
    }
    
    closeSubMenus() {
        this.itemGroups.forEach(group => {
            group.children.forEach(item => {
                item.isOpen = false;
            })
        })
    }

    logout() {
        if (this.$root.currentClient.PortalUseSaml2Authentication) {
            this.authGuard.samlLogoutRedirect();
        } else {
            this.authentication.clearCredentials();
            this.router.navigateByUrl('/login');
        }
    }
    
    private getDashboards(clientPortalTableaudashboards, dashboardGroup: string): MenuItem[] {
        return clientPortalTableaudashboards
            .filter(cptd => cptd.DashboardGroup === dashboardGroup)
            .map(cptd => ({
                title: cptd.Name,
                route: [dashboardGroup.toLowerCase(), cptd.Id],
                permission: 'Analytics'
            }))
    }
}
