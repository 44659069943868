<div class="engineCoverAlerts contentWrapper">
    <form #form="ngForm" (submit)="refresh()" class="toolbar gridFilters">
        <label *ngIf="airports.length > 1">
            <span class="caption">{{'Airport'|translate}}</span>
            <multiselect name="query.AirportId" [(ngModel)]="query.AirportId">
                <multiselect-option *ngFor="let airport of airports|orderBy:'ICAOCode'" [value]="airport.Id" [name]="($root.currentUser.UseIataCode ? (airport.IATACode + '/' + airport.ICAOCode) : airport.ICAOCode + '/' + airport.IATACode) + ' - ' + airport.Name"></multiselect-option>
            </multiselect>
        </label>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date required [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="FromDate" />
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="ToDate" />
        </label>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        </label>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <button type="submit" [disabled]="!form.valid || !query.FromDate || !hasRequiredFilters()"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        </label>
    </form>

    <grid #grid
          gridId="preferences.Change.ColumnExport"
          [query]="query"
          [resource]="api.EngineCoverAlert"
          [headerHeight]="75"
          [columnDefs]="columnDefs">
    </grid>
</div>
